export const PER_ARTICLE_SIZE = 21;
export type BreadcrumbItem = {
  title: string;
  url?: string;
};
export const PcViewWidth = 767;

export type targetPattern =
  | 'jsysTargetA'
  | 'jsysTargetB'
  | 'jsysTargetD'
  | 'jsysTargetE'
  | 'corpHighTarget'
  | 'corpTarget'
  | 'none';

type ReceptionistUrlKeyPattern = 'mukaiUtmComing';

export const receptionistUrls: Record<
  targetPattern | ReceptionistUrlKeyPattern,
  string[]
> = {
  jsysTargetA: ['https://booking.receptionist.jp/josyslpa/60min'],
  jsysTargetB: ['https://booking.receptionist.jp/josyslpb/60min'],
  jsysTargetD: ['https://booking.receptionist.jp/josyslpd/60min'],
  jsysTargetE: ['https://booking.receptionist.jp/josyslpe/60min'],
  corpHighTarget: [
    'https://booking.receptionist.jp/warcagentlpa/60min',
    'https://booking.receptionist.jp/warcagentlpb/60min',
    'https://booking.receptionist.jp/warcagentlpc/60min',
  ],
  corpTarget: [
    'https://booking.receptionist.jp/warcagentlpa/60min',
    'https://booking.receptionist.jp/warcagentlpb/60min',
    'https://booking.receptionist.jp/warcagentlpc/60min',
  ],
  none: [],
  mukaiUtmComing: ['https://booking.receptionist.jp/josyslpc/60min'],
};

type DeviceImageQuality = {
  pc: string;
  sp: string;
};

type ImageQuality = {
  articleList: DeviceImageQuality;
  articleDetail: DeviceImageQuality;
};

export const IMAGE_QUALITY: ImageQuality = {
  articleList: {
    pc: '30',
    sp: '10',
  },
  articleDetail: {
    pc: '50',
    sp: '20',
  },
} as const;

export type dateString = `${number}-${number}-${number}`;

type ArticleCategory = {
  label: string;
  slug: string;
};

export const articleCategories: ArticleCategory[] = [
  {
    label: '経理',
    slug: 'accounting',
  },
  {
    label: '労務',
    slug: 'labor',
  },
  {
    label: 'バックオフィス',
    slug: 'backoffice',
  },
  {
    label: '管理',
    slug: 'management',
  },
  {
    label: '財務',
    slug: 'finance',
  },
  {
    label: '人事',
    slug: 'human_resources',
  },
  {
    label: '法務',
    slug: 'legal',
  },
  {
    label: '会計',
    slug: 'bookkeeping',
  },
  {
    label: 'スタートアップ',
    slug: 'startup',
  },
  {
    label: '監査法人',
    slug: 'auditing_firm',
  },
  {
    label: '経営企画',
    slug: 'business_planning',
  },
  {
    label: 'IR',
    slug: 'investor_relations',
  },
  {
    label: '広報',
    slug: 'public_relations',
  },
  {
    label: 'CFO',
    slug: 'chief_financial_officer',
  },
  {
    label: '情シス',
    slug: 'information_system',
  },
  {
    label: '資格',
    slug: 'certification',
  },
  {
    label: '知識',
    slug: 'knowledges',
  },
  {
    label: '制度',
    slug: 'system',
  },
  {
    label: 'BtoB',
    slug: 'b2b',
  },
  {
    label: 'その他',
    slug: 'others',
  },
  {
    label: '導入事例',
    slug: 'case_studies',
  },
  {
    label: 'ニュース',
    slug: 'news',
  },
] as const;
